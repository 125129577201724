import axios from 'axios';
import applyDefaultSettings from './applyDefaultSettings';
import config from '../config';

const getSite = async () => {
    const firestoreUrl = `${config.firestoreHost}/v1/projects/${config.projectId}/databases/(default)/documents`;

    const siteUrl = `${firestoreUrl}/sites/${window.location.host}`;
    let partnerId;
    try {
        const siteResult = await axios(siteUrl);
        partnerId = siteResult.data.fields.partnerId.stringValue;
    } catch (error) {
        if (error.response.status) return { error: 'App not configured' };
        throw error;
    }

    // const themeUrl = `${firestoreUrl}/partners/${partnerId}/site/theme`;
    // const fetchThemeResult = axios(themeUrl);
    // const themeResult = await fetchThemeResult;
    // const theme = firestoreFieldsToJson(themeResult.data.fields);

    const appSettingsUrl = `${firestoreUrl}/partners/${partnerId}/apps/omkostningsberegningsapp`;
    let appSettingsResult;
    try {
        const fetchAppSettingResult = axios(appSettingsUrl);
        appSettingsResult = await fetchAppSettingResult;
    } catch (error) {
        if (error.response.status) return { error: 'App not configured' };
        throw error;
    }
    const appSettingsResultValues = firestoreFieldsToJson(appSettingsResult.data.fields);
    const settings = applyDefaultSettings(appSettingsResultValues);

    return { partnerId, settings };
};

export default getSite;


const firestoreFieldsToJson = (fields) => {
    if (Array.isArray(fields)) return fields.map(firestoreFieldsToJson);

    if (typeof fields === 'object') {
        const keys = Object.keys(fields);
        if (keys.length === 1) {
            if (keys[0] === 'stringValue') return Object.values(fields)[0];
            if (keys[0] === 'integerValue') return Number(Object.values(fields)[0]);
            if (keys[0] === 'fields') return firestoreFieldsToJson(Object.values(fields)[0]);
            if (keys[0] === 'values') return firestoreFieldsToJson(Object.values(fields)[0]);
            if (keys[0] === 'mapValue') return firestoreFieldsToJson(Object.values(fields)[0]);
            if (keys[0] === 'arrayValue') {
                if (isEmpty(Object.values(fields)[0])) return [];
                return firestoreFieldsToJson(Object.values(fields)[0]);
            }
        }

        const result = { };
        Object.keys(fields).forEach((key) => {
            result[key] = firestoreFieldsToJson(fields[key]);
        });
        return result;
    }

    return fields;
};

const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};
