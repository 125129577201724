const applyDefaultSettings = (appSettings) => {

    const defaultTexts = {
        amountTitle: 'Hvor mange penge vil du investere?',
        amountHelpText: 'Indtast det beløb du ønsker at investere. Det kan være penge du allerede har investeret eller ønsker at investere.',
        strategyTitle: 'Vælg din risikoprofil',
        strategyHelpText: 'Vælg det risko niveau du mener passer bedst til dine investeringer.',
        calculationInfoUrlText: 'Se sammenligningsmetode og forudsætninger',
        resultInformation: `
### Lavere omkostninger

Dine investeringer forvaltes bedre hos os, fordi vi har lavere omkostninger end mange andre tilbud om formueforvaltning.

Med en investering på **{{amountKr}}** og risikoprofil **{{strategyName}}**, sparer du årligt **{{savedKr}}** i omkostninger. Til sammenligning vil du typisk betale **{{totalBaseCostKr}}** hos andre markedstilbud.

\\* Vores honorar for risikoprofil **{{strategyName}}** er kun **{{strategyManagementCostPercent}}**. Dertil kommer omkostninger i de aktuelle fonde på typisk 0,2% - 0,7%.'
        `,
        email: 'info@monax.dk',
        phone: '40866164'
    };

    const defaultUrls = {
        calculationInfoUrl: 'https://monax.dk/',
        privacyUrl: 'https://monax.dk/'
    };

    const defaultColors = {
        bodyColor: '#ffffff',
        primaryColor: '#0f8685',
        secondaryColor: '#0a7271',
        tertiaryColor: '#6f6f6f',
        containerColor: '#f3f3f3',
        containerColorHighlight: '#e6ebeb',
        textColor: '#535353',
        inputColor: '#ffffff',
        borderColor: '#747474',
        resultColorMarket: '#6f6f6f',
        resultColorPartner: '#4f8c91',
        strategyColor1: 'rgb(78, 141, 78)',
        strategyColor2: 'rgb(240, 189, 94)',
        strategyColor3: 'rgb(247, 109, 109)',
        strategyColor4: 'rgb(248, 69, 69)',
        strategyColor5: 'rgb(180, 13, 13)',
        strategyColor6: 'rgb(85, 0, 0)',
        errorColor: 'rgb(247, 142, 142)'
    };

    const defaultFonts = {
        fontUrl: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swa',
        fontFamily: 'Roboto, BlinkMacSystemFont, sans-serif',
	    fontSize: '16px'
    };

    const defaultContact = {
        phone: 'mandatory',
        email: 'mandatory',
        postalCode: 'optional',
        comment: 'optional',
    };

    const settings = {
        state: 'initial',
        stateMessage: null,
        minimumValue: appSettings.minimumValue ?? 5000,
        defaultValue: appSettings.defaultValue ?? 500000,
        maximumValue: appSettings.maximumValue ?? 1000000000,
        strategies: appSettings.strategies || [],
        contact: { ...defaultContact, ...appSettings.contact },
        texts: { ...defaultTexts, ...appSettings.texts },
        urls: { ...defaultUrls, ...appSettings.urls },
        colors: { ...defaultColors, ...appSettings.colors },
        fonts: { ...defaultFonts, ...appSettings.fonts },
        css: null
    };

    // Verify risk strategies
    if (!settings.strategies || settings.strategies.length === 0) {
        settings.state = 'notconfigured';
        settings.stateMessage = 'Appen er ikke konfigureret.';
        return settings;
    };

    // Generate CSS output
    const cssFontUrl= settings.colors.fontUrl ? `@import url("${settings.colors.fontUrl}")` : '';
    settings.css = `${cssFontUrl}
                :root {
                --body-color: ${settings.colors.bodyColor};
                --primary-color: ${settings.colors.primaryColor};
                --secondary-color: ${settings.colors.secondaryColor};
                --tertiary-color: ${settings.colors.tertiaryColor};
                --container-color: ${settings.colors.containerColor};
                --container-colorhighlight: ${settings.colors.containerColorHighlight};
                --text-color: ${settings.colors.textColor};
                --input-color: ${settings.colors.inputColor};
                --border-color: ${settings.colors.borderColor};
                --result-color-market: ${settings.colors.resultColorMarket};
                --result-color-partner: ${settings.colors.resultColorPartner};
                --result-color-saved: var(--primary-color);
                --strategy-color1: ${settings.colors.strategyColor1};
                --strategy-color2: ${settings.colors.strategyColor2};
                --strategy-color3: ${settings.colors.strategyColor3};
                --strategy-color4: ${settings.colors.strategyColor4};
                --strategy-color5: ${settings.colors.strategyColor5};
                --strategy-color6: ${settings.colors.strategyColor6};
                --error-color: ${settings.colors.errorColor};
                --font-family: ${settings.fonts.fontFamily};
                --font-size: ${settings.fonts.fontFamily};
            }`;

    settings.state = 'verified';

    return settings;
};

export default applyDefaultSettings;
