const renderKr = (amount, maximumDigits = 0, minimumDigits = 0) => {
    const formatter = Intl.NumberFormat('da', {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    });

    return formatter.format(amount);
};

function renderPercent(x, maximumDigits = 2, minimumDigits = 0) {
    if (x === undefined) {
        return '0%';
    }
    return `${(x * 100).toLocaleString('da', {
        minimumFractionDigits: minimumDigits,
        maximumFractionDigits: maximumDigits,
    })}%`;
}

function renderDate(date) {
    let d = date;

    if (typeof d === 'string') d = new Date(d);
    if (typeof d.toDate !== 'undefined') d = date.toDate();

    return d.toLocaleString('da-DK', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}

module.exports = {
    renderKr,
    renderPercent,
    renderDate,
};
