import envs from './envs.json';

const env = process.env.REACT_APP_ENV || 'local';
const version = process.env.REACT_APP_VERSION;

console.log(`Monax omkostningsberegningsapp: ${env} build - version ${version}`); // eslint-disable-line no-console

const config = { ...envs[env], env, version };

export default config;
