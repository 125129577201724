import React, { useEffect, useState } from 'react';

import Loading from './components/Loading';
import Calculator from './components/Calculator';
import getSite from './lib/getSite';

function App() {
    const [site, setSite] = useState();

    useEffect(() => {
        const fetchSite = async () => {
            const response = await getSite();
            setSite(response);
        };
        fetchSite();
    }, []);

    return (
        <>
            {
                (!site && <Loading />)
                || (site.error && <p>{site.error}</p>)
                || (site.settings.state !== 'verified' && <p>{site.settings.stateMessage}</p>)
                || (
                    <>
                        <style type='text/css'>{`${site?.settings?.css}}`}</style>
                        <Calculator site={site} />
                    </>
                )
            }
        </>
    );
}

export default App;
