import axios from 'axios';
import config from '../config';

const submit = async (site, data) => {
    const functionUrl = `${config.functionsHost}/apps-omkostningsberegner-submit-email`;

    let result;
    try {
        result = await axios({
            method: 'POST',
            url: functionUrl,
            data: { data, partnerId: site.partnerId },
        });
    } catch (error) {
        if (error.response.status === 400) return error.response.data;
    }

    return result.data;
};

export default submit;
