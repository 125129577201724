import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderKr } from 'monax-rendering';

function CurrencyInputComponent({ value, name, disabled, onChange, style, min, max }) {
    const [isActive, setIsActive] = useState(false);
    const [error, setError] = useState('');
    const [internalValue, setInternalValue] = useState(value.toString());

    function wrappedOnChange(event) {
        if (!event.target.value.length) {
            setInternalValue('');
            onChange(0);
            return;
        }

        const inputValue = parseStringToNumber(event.target.value);
        if (typeof inputValue !== 'number') return false;

        if (inputValue < min) {
            setError(`Minimum ${renderKr(min, 5, 0)}`);
            setInternalValue(event.target.value);
            onChange(inputValue);
            return;
        }
        if (inputValue > max) {
            setError(`Maximum ${renderKr(max, 5, 0)}`);
            setInternalValue(event.target.value);
            return;
        }

        setError();
        setInternalValue(event.target.value);
        onChange(inputValue);
    }

    return (
        <>
            <span>{ error }</span>
            <input
                disabled={disabled}
                name={name}
                type='text'
                value={isActive ? internalValue : renderKr(internalValue, 5, 0)}
                onChange={wrappedOnChange}
                style={{ ...style }}
                onFocus={() => setIsActive(true)}
                onBlur={() => setIsActive(false)}
                onKeyDown={(e) => e.key === 'Enter' && document.activeElement.blur()}
            />
        </>
    );
}

function parseStringToNumber(string) {
    if (!string.length) return;
    if (string.match(/[^0-9]/)) return;
    return Number.parseFloat(string);
}

CurrencyInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
};

CurrencyInputComponent.defaultProps = {
    disabled: false,
    style: { },
};

export default CurrencyInputComponent;
